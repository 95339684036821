import DrawWindow from "@/utils/classes/DrawWindow";
import type { ProductVariant } from "@/types/productvariant.types";
import type { ConfigurationMarker } from "@/types/configurationmarkers.types";
import { useRoomStore } from "@/stores";
import { Graphics, Container } from 'pixi.js';

export default class DrawWindow {
    public windowGraphics: any;
    private configurationMarker: ConfigurationMarker;
    private width: number;
    private product_variant: ProductVariant;
    private container: Container;

	constructor(options: {
		configurationMarker: ConfigurationMarker,
        product_variant: ProductVariant,
        container: Container,
	}) {
        this.configurationMarker = options.configurationMarker
        this.product_variant = options.product_variant;
		this.width = this.product_variant?.width ?? 150,
		this.container = options.container

		this.windowGraphics = this.setupWindow();
    }

	private setupWindow() {
		const graphics = new Graphics();
		const pos = this.configurationMarker.position_data;

		redrawWindow(graphics, this.width);

		graphics.angle = pos.rotation;

		graphics.position.set(pos.x, pos.y);
        graphics.xid = this.configurationMarker.xid;

		this.container.addChild(graphics);
		return graphics;
	}
}


/**
 * Draws the window line(s)
 */
export function redrawWindow(graphic: any, windowWidth: number){
    if(!graphic) {
        return;
    }
    graphic.clear();

    const roomStore = useRoomStore();
    const { wallThickness, windowColor } = roomStore;

    graphic.lineStyle(1, 0xA8A8A8);
    graphic.beginFill(windowColor, 1);
    graphic.drawRect(-windowWidth/2, -wallThickness, windowWidth, wallThickness);

    //Draw small tablet
    graphic.drawRect(-(windowWidth / 2) - 5, -(wallThickness / 4), (windowWidth + 10), (wallThickness / 4));

    graphic.zIndex = 110
}

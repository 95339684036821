import { ref, computed } from "vue";
import { DrawingType } from '@/types/drawinglogic.types';

export const floorGraphics = ref(null);
export const wallGraphics = ref(null);
export const windowGraphics= ref([]);
export const doorGraphics= ref([]);
export const markerGraphics= ref([]);
export const lightGraphics= ref([]);
export const roomDimensions= ref(null);

export const drawingGraphicsMap = computed(() => ({
    [DrawingType.FURNITURE]: markerGraphics.value,
    [DrawingType.LIGHTING]: lightGraphics.value,
    [DrawingType.WINDOW]: windowGraphics.value,
    [DrawingType.DOOR]: doorGraphics.value,
    [DrawingType.WALL]: wallGraphics.value,
    [DrawingType.FLOOR]: floorGraphics.value,
}));

export function clearGlobalValues() {
    floorGraphics.value = null;
    wallGraphics.value = null;
    windowGraphics.value = [];
    doorGraphics.value = [];
    markerGraphics.value = [];
    lightGraphics.value = [];
    roomDimensions.value = null;
}